import { Exportable } from "@models/index";

export class Attachment implements Exportable {

  id:        number = 0;
  version:   number | undefined;
  name:      string | undefined;
  entity:    string | undefined;
  entityId:  number | undefined;
  uuid:      string | undefined;
  mediaType: string | undefined;

  constructor() {}

  toString() {
    return `Foo (id: ${this.id})`;
  }

  test() {
    return 'asdfs ';
  }
}
